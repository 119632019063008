import React, { PureComponent } from 'react';
import { Mobile } from 'utils/responsiveHelpers';
import config from 'config';
import { NavLink } from 'react-router-dom';
import _t from 'counterpart';
import dailyOverviewIcon from '../../icons/daily_overview.svg?url';
import settingsIcon from '../../icons/settings.svg?url';
import referIcon from '../../icons/refer.svg?url';
import leaderboardIcon from '../../icons/leaderboard_white.svg?url';
import depositWithdrawIcon from '../../icons/withdraw_deposit.svg?url';
import logoutIcon from '../../icons/logout.svg?url';
import targetIcon from '../../icons/target.svg?url';
import uploadDocumentsIcon from '../../icons/upload-documents.svg?url';
import copyIcon from '../../icons/copy.svg?url';
import walletIcon from '../../icons/wallet.svg?url';
import shareIcon from '../../icons/share.svg?url';
import closeIcon from '../../icons/close.svg?url';
import copyTradingIcon from '../../icons/copy_trading.svg?url';
import { actions, selectors } from 'wintrado-api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Footer from 'components/main/Footer';
import MessagesIcon from 'components/MessagesIcon';
import AnyLink from 'components/global/AnyLink';
import UserAvatar from 'components/global/UserAvatar';
import WalletPicker from 'components/WalletPicker/WalletPicker';
import AnimatedSwitcher from 'components/AnimatedSwitcher/AnimatedSwitcher';

class MobileSideDrawer extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			copied: false,
			activePageIndex: 0,
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.sideDrawerShown && !this.props.sideDrawerShown && this.state.activePageIndex === 1) {
			this.resetToFirstSlide();
		}
	}

	closeSidebar = () => {
		this.props.hideSideDrawer();
	};

	handleOverlayClicked = (e) => {
		e.preventDefault();
		e.stopPropagation();
		this.closeSidebar();
	};

	handleShareReferral = (e) => {
		e.preventDefault();

		const { referralURL } = this.props;
		const { appName } = config;

		navigator
			.share({
				title: appName,
				url: _t.translate('main.invite_link', {
					referralURL,
				}),
			})
			.catch(console.error);
	};

	handleCopyReferral = (e) => {
		e.preventDefault();

		const { referralURL } = this.props;
		const tempInput = document.createElement('input');
		tempInput.style = 'position: absolute; left: -1000px; top: -1000px';
		tempInput.value = _t.translate('main.invite_link', {
			referralURL,
		});
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand('copy');
		document.body.removeChild(tempInput);
		this.handleCopiedLabel();
	};

	handleCopiedLabel = () => {
		this.setState({
			copied: true,
		});
		setTimeout(() => {
			this.setState({
				copied: false,
			});
		}, 2000);
	};

	handleSwitchWalletClicked = (e) => {
		e.preventDefault();
		this.setState({
			activePageIndex: 1,
		});
	};

	resetToFirstSlide = () => {
		this.setState({
			activePageIndex: 0,
		});
	};

	render() {
		const {
			sideDrawerShown,
			username,
			features,
			referralURL,
			token,
			supportsMessages,
			supportsCompetitions,
			supportsCopytrading,
			currentWallet,
		} = this.props;
		return (
			<Mobile>
				<div onClick={this.handleOverlayClicked} className={`side-drawer--overlay${sideDrawerShown ? ' show' : ''}`} />
				<div className={`side-drawer${sideDrawerShown ? ' show' : ''}`}>
					<div className="drawer-header">
						<div className="drawer-user">
							<div className="holder">
								<div className="avatar-holder">
									<UserAvatar />
								</div>
								<div className="info">
									<p
										className="wallet-label"
										dangerouslySetInnerHTML={{
											__html: _t.translate('account_info.wallet', {
												name: `<span class="wallet-label wallet-label--accent">${currentWallet?.username}</span>`,
											}),
										}}
									/>
									<p className="user">{username}</p>
								</div>
							</div>
						</div>
						{supportsMessages && (
							<NavLink onClick={this.closeSidebar} exact to="/messages" className="messages">
								<MessagesIcon />
							</NavLink>
						)}
						<button type="button" className="close-side-drawer" onClick={this.closeSidebar}>
							<img src={closeIcon} alt="close" />
						</button>
					</div>

					<div className="content">
						{/* @ts-ignore */}
						<AnimatedSwitcher className="slider" activeIndex={this.state.activePageIndex}>
							<div className="main-content-wrapper">
								<button type="button" className="button" onClick={this.handleSwitchWalletClicked}>
									<img src={walletIcon} alt="wallet-icon" />
									<span className="title">{_t.translate('page.switch_wallet')}</span>
								</button>
								<NavLink
									onClick={this.closeSidebar}
									className="button"
									to="/account-statement"
									title={_t.translate('page.account_statement')}
								>
									<img src={dailyOverviewIcon} alt="daily-overview-icon" />
									<span className="title">{_t.translate('page.account_statement')}</span>
								</NavLink>
								{config.cashier && (token !== null || config.cashier.depositURL) && (
									<button
										type="button"
										className="button"
										onClick={() => {
											this.props.showDepositWithdrawal();
											this.closeSidebar();
										}}
									>
										<img src={depositWithdrawIcon} alt="deposit-withdraw-icon" />
										<span className="title">{_t.translate('main.withdrawOrDeposit')}</span>
									</button>
								)}
								{supportsCopytrading && (
									<NavLink
										onClick={this.closeSidebar}
										className="button"
										to="/copy-trading/strategies"
										title={_t.translate('page.copy_trading')}
									>
										<img src={copyTradingIcon} alt="copy-trading-icon" />
										<span className="title">{_t.translate('page.copy_trading')}</span>
									</NavLink>
								)}
								<NavLink
									onClick={this.closeSidebar}
									className="button"
									to="/settings"
									title={_t.translate('main.settings')}
								>
									<img src={settingsIcon} alt="settings-icon" />
									<span className="title">{_t.translate('main.settings')}</span>
								</NavLink>
								{supportsCompetitions && (
									<NavLink
										onClick={this.closeSidebar}
										className="button"
										to="/leaderboard"
										title={_t.translate('leaderboard.heading')}
									>
										<img src={leaderboardIcon} alt="leaderboard" />
										<span className="title">{_t.translate('leaderboard.heading')}</span>
									</NavLink>
								)}

								{features.referFriendsEnabled && referralURL && (
									<div className="refer-container">
										<div className="icon-wrapper">
											<img src={referIcon} alt="refer-icon" />
										</div>
										<div className="content-wrapper">
											<span className="title">{_t.translate('main.your_referral_link')}</span>
											<br />

											<span className="link">
												{_t.translate('main.invite_link', {
													referralURL,
												})}
											</span>

											<div className="buttons">
												<button
													type="button"
													onClick={this.handleCopyReferral}
													className={this.state.copied ? 'copied' : 'copy'}
												>
													<img src={copyIcon} alt="copy-icon" />
													{this.state.copied ? _t.translate('main.copied') : _t.translate('main.copy')}
												</button>
												{Boolean(navigator.share) && (
													<button type="button" onClick={this.handleShareReferral} className="share">
														<img src={shareIcon} alt="share-icon" />
														{_t.translate('main.share_action')}
													</button>
												)}
											</div>
										</div>
									</div>
								)}

								{config.affiliatePortalUrl && (
									<AnyLink
										onClick={this.closeSidebar}
										className="button"
										to={`${config.affiliatePortalUrl}/login?token=${token}`}
										title={_t.translate('main.marketing')}
									>
										<img src={targetIcon} alt="marketing-icon" />
										<span className="title">{_t.translate('main.marketing')}</span>
									</AnyLink>
								)}
								{config.cashier && config.cashier.uploadDocumentsURL && (
									<NavLink className="button" to="/upload-documents" onClick={this.closeSidebar}>
										<img src={uploadDocumentsIcon} alt="upload-documents-icon" />
										<span className="title">{_t.translate('main.upload-documents')}</span>
									</NavLink>
								)}
								<button
									type="button"
									className="button logout"
									onClick={() => {
										this.props.logout({ userRequested: true, withOAuth: true });
										this.closeSidebar();
									}}
								>
									<img src={logoutIcon} alt="logout-icon" />
									<span className="title">{_t.translate('modal.logout')}</span>
								</button>
							</div>
							<div className="wallet-picker-container">
								<WalletPicker onBack={this.resetToFirstSlide} />
							</div>
						</AnimatedSwitcher>
					</div>
					<div className="footer">
						<Footer onClick={this.closeSidebar} />
					</div>
				</div>
			</Mobile>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		username: state.user.name,
		features: state.settings.features,
		sideDrawerShown: state.events.sideDrawerShown,
		token: state.user.token,
		referralURL: state.user.referralURL,
		supportsMessages: selectors.supportsMessagesSelector(state),
		supportsCompetitions: selectors.supportsCompetitionsSelector(state),
		currentWallet: state.user.wallet,
		supportsCopytrading: selectors.supportsCopytrading(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators(
		{
			hideSideDrawer: actions.hideSideDrawer,
			showDepositWithdrawal: actions.showDepositWithdrawalModal,
			logout: actions.logoff,
		},
		dispatch
	);
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileSideDrawer);
